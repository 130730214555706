import { useEffect, useState } from "react";
import { FaPaperclip, FaPaperPlane, FaXmark } from "react-icons/fa6";
import FileUploader from "./FileUploader";
import MediaViewer from "./MediaViewer";
import Overlay from "./Overlay";
import { FaSearch } from "react-icons/fa";
function Messenger(props) {
	const [message, setMessage] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [searchTimeout, setSearchTimeout] = useState(null);

	const sendMessage = () => {
		props.socket.emit("sendMessage", {
			to: props.activeDialog,
			message,
		});
		setMessage("");
	};

	useEffect(() => {
		if (searchTimeout) clearTimeout(searchTimeout);
		const timeoutId = setTimeout(() => {
			props.searchMessages(searchText);
		}, 1000);
		setSearchTimeout(timeoutId);
	}, [searchText]);

	useEffect(() => {
		setMessage("");
	}, [props.activeDialog]);

	const getFormattedDateTime = (date, type) => {
		const datetime = new Date(date);
		const day = String(datetime.getDate()).padStart(2, "0");
		const month = String(datetime.getMonth() + 1).padStart(2, "0");
		const hours = String(datetime.getHours()).padStart(2, "0");
		const minutes = String(datetime.getMinutes()).padStart(2, "0");
		return type === 1 ? `${day}.${month}` : `${hours}:${minutes}`;
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.ctrlKey ? setMessage((msg) => msg + "\n") : sendMessage();
		}
	};

	const gotoMessage = (id) => {
		setIsSearching(false);
		setSearchText("");
		props.setSearchedMessages([]);
		if (props.messages.find((x) => x.id === id)) {
			setTimeout(() => {
				document
					.getElementById("message" + id)
					.scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "nearest",
					});
			}, 100);
		} else {
			props.socket.emit("gotoMessage", { to: id, id: props.activeDialog });
		}
	};

	const renderMessageDate = (message, prevMessage, key) => {

		if (!prevMessage) return null;
		const isNewDay = new Date(message?.date).toDateString() !== new Date(prevMessage?.date).toDateString()
		return isNewDay ? (
			<p key={key + 9999} className="NewDay">
				{getFormattedDateTime(message.date, 1)}
			</p>
		) : null;
	};

	return (
		<div
			className={
				props.activeDialog !== 0 || props.isInfoOpened
					? "Messanger"
					: "Messanger Hidden"
			}
		>
			{props.isInfoOpened && (
				<Overlay
					getPaymentInfo={props.getPaymentInfo}
					orderInfo={props.orderInfo}
          setOrderInfo={props.setOrderInfo}
					setIsInfoOpened={props.setIsInfoOpened}
					socket={props.socket}
					triggerNotification={props.triggerNotification}
					order={props.order}
				/>
			)}

			{props.activeDialog !== 0 && (
				<>
					<div className="Header">
						<FaXmark
							className="close"
							onClick={() => props.setActiveDialog(0)}
						/>
						<p className="Avatar">
							{
								props.dialogs?.find((x) => x.tgId === props.activeDialog)
									?.name[0]
							}
						</p>
						<p className="Nickname">
							{props.dialogs?.find((x) => x.tgId === props.activeDialog)?.name}
						</p>
						<FaSearch className="search" onClick={() => setIsSearching(true)} />
					</div>

					{isSearching ? (
						<>
							<div className="Container">
								{props.searchedMessages.map((message, key) => (
									<>
										{renderMessageDate(message, props.messages[key - 1], key)}
										{message.source === "PANEL" &&
										message.panelUserId === -1 ? (
											<p style={{ textAlign: "center" }}>{message.text}</p>
										) : (
											<div
												key={key}
												className={
													message.source === "PANEL" ? "Message My" : "Message"
												}
												onClick={() => gotoMessage(message.id)}
											>
												{message.source === "PANEL" && (
													<p className="MessageFrom">
														{props.supports.find(
															(s) => s.id === message.panelUserId
														)?.displayName || "Support"}
													</p>
												)}
												<p>{message.text}</p>
												{message.attachLink && (
													<div className="Attach">
														<MediaViewer
															src={`/api/media/${message.attachLink}`}
															type={
																message.attachLink.endsWith(".jpg")
																	? "image"
																	: message.attachLink.endsWith(".mp4")
																	? "video"
																	: "file"
															}
														/>
													</div>
												)}
												<p className="When">
													{getFormattedDateTime(message.date)}
												</p>
											</div>
										)}
									</>
								))}
							</div>
							<div className="SendMessage">
								<textarea
									value={searchText}
									onChange={(e) => setSearchText(e.target.value)}
									placeholder="Поиск"
								/>
							</div>
						</>
					) : (
						<>
							<div className="Container">
								{!props.messages[props.messages.length - 1]?.isFirst && (
									<button
										style={{ width: "150px", alignSelf: "center" }}
										onClick={() =>
											props.socket.emit("loadMessages", {
												id: props.activeDialog,
												from: props.messages[0].id,
											})
										}
									>
										Загрузить ещё
									</button>
								)}
								{props.messages.map((message, key) => (
									<>
										{renderMessageDate(message, props.messages[key - 1], key)}
										{message.source === "PANEL" &&
										message.panelUserId === -1 ? (
											<p style={{ textAlign: "center" }}>{message.text}</p>
										) : (
											<div
												key={key}
												id={"message" + message.id}
												className={
													message.source === "PANEL" ? "Message My" : "Message"
												}
											>
												{message.source === "PANEL" && (
													<p className="MessageFrom">
														{props.supports.find(
															(s) => s.id === message.panelUserId
														)?.displayName || "Support"}
													</p>
												)}
												<p>{message.text}</p>
												{message.attachLink && (
													<div className="Attach">
														<MediaViewer
															src={`/api/media/${message.attachLink}`}
															type={
																message.attachLink.endsWith(".jpg")
																	? "image"
																	: message.attachLink.endsWith(".mp4")
																	? "video"
																	: "file"
															}
														/>
													</div>
												)}
												<p className="When">
													{getFormattedDateTime(message.date)}
												</p>
											</div>
										)}
									</>
								))}
							</div>
							<div className="SendMessage">
								<FileUploader
									activeDialog={props.activeDialog}
									socket={props.socket}
								/>
								<textarea
									onKeyDown={handleKeyDown}
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									placeholder="Сообщение"
								/>
								<FaPaperPlane onClick={sendMessage} />
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default Messenger;
