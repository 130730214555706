import "../css/MainWindow.css";
import "../css/Auth.css";

import { FaUserLarge, FaMessage, FaGear } from "react-icons/fa6";
import { useState, useEffect, useRef } from "react";
import useUser from "../hooks/useUser";
import NotificationSystem from "../components/Notifications";

function Auth() {
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");

	const user = useUser();

	useEffect(() => {
		if (user.loaded && !user.error) {
			window.location.href = "/panel";
		}
	}, [user]);

	function auth() {
		fetch("/api/login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ login: login, password: password }),
		})
			.then(async (res) => {
				if (res.status !== 200) throw await res.json();
				window.location.href = "/panel";
			})
			.catch((e) => {
				console.log("error", e);
				triggerNotification(
					e.error === 2
						? "Неверный логин или пароль!"
						: "Сейчас не ваше рабочее время"
				);
			});
	}

	const notificationSystemRef = useRef();

	const triggerNotification = (t) => {
		if (notificationSystemRef.current) {
			notificationSystemRef.current.addNotification(t);
		}
	};

	return (
		<>
			<div className="Main">
				<div className="Window AuthWindow">
					<div className="Auth">
						<h1>Авторизация</h1>
						<input
							value={login}
							onChange={(e) => setLogin(e.target.value)}
							placeholder="Логин"
						/>
						<input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Пароль"
						/>
						<button
							onClick={() => {
								auth();
							}}
						>
							Войти
						</button>
					</div>
				</div>
			</div>
			<NotificationSystem ref={notificationSystemRef} />
		</>
	);
}

export default Auth;
