import { useEffect, useState } from "react";

function UsersManagment(props) {
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [displayName, setDisplayName] = useState("");

	const [status, setStatus] = useState("");

	const [workingHours, setWorkingHours] = useState([0, 24]);

    useEffect(() => {
        setLogin(props.modalUser?.login ? props.modalUser?.login : "");
        setPassword("");
        setDisplayName(props.modalUser?.displayName ? props.modalUser?.displayName : "");
        setWorkingHours(props.modalUser?.workingHours ? props.modalUser?.workingHours : [1, 24]);
        setStatus(props.modalUser?.status ? props.modalUser?.status : 1);
    }, [props.modalUser])


    async function updateUser() {
        if(password !== "") {
            await fetch("/api/updateUserPassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ id: props.modalUser.id, password: password })
            })
                .then(async (res) => {
                    if (res.status !== 200) throw true;
                })
                .catch((e) => {
                    console.log("error");
                    props.triggerNotification("Ошибка при изменении пароля пользователя")
                });
        }

        fetch("/api/updateUser", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
            body: JSON.stringify({ login, displayName, workingHours, status, id: props.modalUser.id })
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
                props.setModalOpened(false);

			})
			.catch((e) => {
				console.log("error");
                props.triggerNotification("Ошибка при изменении пользователя")
			});
    }


	function addUser() {
        fetch("/api/createUser", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
            body: JSON.stringify({ login, password, displayName, workingHours, status })
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
                props.setModalOpened(false);
                props.setModalUser({});
			})
			.catch((e) => {
				console.log("error");
                props.triggerNotification("Ошибка при добавлении пользователя")
			});
    }

	return (
		<div className="Modal">
			<div className="Content">
				<h1>{props.modalUser.id ? "Редактирование пользователя ID: "+props.modalUser.id : "Создание пользователя" }</h1>
				<input value={login} onChange={(e) => { setLogin(e.target.value) }} placeholder="Логин" />
				<input value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder="Пароль" />
				<input value={displayName} onChange={(e) => { setDisplayName(e.target.value) }} placeholder="Отображаемое имя" />

				<p>Статус</p>
				<select value={status} onChange={(e) => { setStatus(e.target.value) }}>
					<option value={1}>Активный</option>
					<option value={2}>Забанен</option>
					<option value={3}>Администратор</option>
				</select>

				<p>Время работы</p>
				<div
					style={{
						display: "flex",
						flexWrap: "nowrap",
						gap: "15px",
						alignItems: "center",
					}}
				>
					<p>От:</p>
					<select onChange={(e) => { setWorkingHours(workingHours => [Number(e.target.value), workingHours[1]] )}} value={workingHours[0]}>
						{new Array(25).fill("null").map((_, key) => (
							<option value={key} key={key}>
								{key}
							</option>
						))}
					</select>

					<p>До:</p>
					<select onChange={(e) => { setWorkingHours(workingHours => [workingHours[0], Number(e.target.value)] )}} value={workingHours[1]}>
						{new Array(25).fill("null").map((_, key) => (
							<option value={key} key={key}>
								{key}
							</option>
						))}
					</select>
				</div>
                {props.modalUser.id ? <button onClick={() => { updateUser() }}>Изменить</button> : <button onClick={() => { addUser() }}>Создать</button>}
                <button onClick={() => { props.setModalOpened(false); }}>Закрыть</button>
			</div>
		</div>
	);
}

export default UsersManagment;
