import { useEffect, useState } from "react";
import { FaPaperclip, FaPaperPlane, FaXmark } from "react-icons/fa6";

function Overlay(props) {
	const [requisites, setRequisites] = useState("");

	const [bank, setBank] = useState("");

	function checkManually() {
		fetch(`https://yellowchanger.com/api/check_exchange_manual.php`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exch_id: props.orderInfo.exchangeId,
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return res.json();
			})
			.then((res) => {
				props.triggerNotification(
					res.status === "sent"
						? "Перевод отправлен"
						: res.status === "notverifed"
						? "Клиент не прошёл верификацию"
						: res.status === "not_paid"
						? "Оплата не найдена"
						: res.status
				);
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});
	}

	function updateRequisites() {
		fetch(`https://api.yellowchanger.com/support/changeRequisites`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exchangeId: props.orderInfo.exchangeId,
				new_data: requisites,
				newbank: bank,
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				res = await res.json();

				if (res !== false) {
					props.setOrderInfo(res);
					props.triggerNotification("Реквизиты изменены!");
					props.socket.emit("newRequisites", {
						requisites: requisites,
						order: props.order,
					});
				} else {
					props.triggerNotification("Ошибка при изменении реквизитов!");
				}
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});

		setRequisites("");
	}

	function forceCancelTrade() {
		fetch(`https://api.yellowchanger.com/support/cancelTrade`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exchangeId: props.orderInfo.exchangeId,
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				res = await res.json();

				if (res !== false) {
					props.triggerNotification("Обмен отменён!");
					props.setOrderInfo(res);
				} else {
					props.triggerNotification("Ошибка при получении данных!");
				}
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});

		setRequisites("");
	}

	function forceSetInvalidRequisites() {
		fetch(`https://api.yellowchanger.com/support/setInvalidRequisites`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exchangeId: props.orderInfo.exchangeId,
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				res = await res.json();
				if (res !== false) {
					props.triggerNotification("Статус изменён!");
					props.setOrderInfo(res);
				} else {
					props.triggerNotification(
						"Заявка на вывод еще не была создана! Попробуйте позже."
					);
				}
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});

		setRequisites("");
	}

	return (
		<>
			<div className="MessangerOverlay">
				<FaXmark
					onClick={() => props.setIsInfoOpened(false)}
					className="Close"
				/>

				<div className="OverlayInfo">
					{props.orderInfo.status ? (
						<p>
							Статус: {props.orderInfo.normalizedStatus} (
							{props.orderInfo.status})
						</p>
					) : null}
					{props.orderInfo.otcTradeStatus ? (
						<p>Статус вывода рублей: {props.orderInfo.otcTradeStatus}</p>
					) : null}
					{props.orderInfo.direction ? (
						<p>Направление: {props.orderInfo.direction}</p>
					) : null}
					{props.orderInfo.otcDirection ? (
						<p>Направление вывода рублей: {props.orderInfo.otcDirection}</p>
					) : null}
					{props.orderInfo.sendAmount ? (
						<p>Сумма отправления: {props.orderInfo.sendAmount}</p>
					) : null}
					{props.orderInfo.getAmount ? (
						<p>Сумма получения: {props.orderInfo.getAmount}</p>
					) : null}
					{props.orderInfo.exchType ? (
						<p>Тип обмена: {props.orderInfo.exchType}</p>
					) : null}
					{props.orderInfo.creationDate ? (
						<p>Дата создания: {props.orderInfo.creationDate}</p>
					) : null}
					{props.orderInfo.receivedDate ? (
						<p>Дата отправки: {props.orderInfo.receivedDate}</p>
					) : null}
					{props.orderInfo.sendCredentials ? (
						<p>Адрес для оплаты: {props.orderInfo.sendCredentials}</p>
					) : null}
					{props.orderInfo.getCredentials ? (
						<p>Адрес получения: {props.orderInfo.getCredentials}</p>
					) : null}
					{props.orderInfo.userPaidHash &&
					props.orderInfo.userPaidHash !== "None" ? (
						<p>
							Хэш оплаты:{" "}
							<a href={props.orderInfo.userPaidHash}>
								{props.orderInfo.userPaidHash}
							</a>
						</p>
					) : null}
					{props.orderInfo.ourHash && props.orderInfo.ourHash !== "None" ? (
						<p>
							Хэш отправки:{" "}
							<a href={props.orderInfo.ourHash}>{props.orderInfo.ourHash}</a>
						</p>
					) : null}
				</div>

				{props.orderInfo.otcTradeStatus &&
				props.orderInfo.otcTradeStatus == "Ожидает выплаты банком" &&
				props.orderInfo.status != "3" &&
				props.orderInfo.status != "successfull" ? (
					<button
						onClick={() => {
							forceSetInvalidRequisites();
						}}
						style={{ maxWidth: "400px" }}
					>
						Установить статус "Невалидные реквизиты"
					</button>
				) : null}

				{props.orderInfo.status &&
				props.orderInfo.status != "3" &&
				props.orderInfo.status != "successfull" &&
				props.orderInfo.status != "4" &&
				props.orderInfo.status != "cancel" ? (
					<button
						onClick={() => {
							forceCancelTrade();
						}}
						style={{ maxWidth: "400px" }}
					>
						Отменить обмен
					</button>
				) : null}

				{props.orderInfo.status &&
				props.orderInfo.otcTradeStatus === "Невалидные реквизиты" ? (
					<>
						<select value={bank} onChange={(e) => setBank(e.target.value)}>
							<option value={""}></option>
							<option value={"sbppsb"}>СБП ПСБ (Промсвязь) </option>
							<option value={"sbpakbars"}>СБП Ак-Барс </option>
							<option value={"sbprnkb"}>СБП РНКБ </option>
							<option value={"sbpotp"}>СБП ОТП </option>
							<option value={"sbpozon"}>СБП ОЗОН </option>
							<option value={"sbpmtc"}>СБП МТС </option>
							<option value={"sbppochtabank"}>СБП ПочтаБанк </option>
							<option value={"sbpumoney"}>СБП Юмани </option>
							<option value={"sbptinkoff"}>СБП Тинькофф </option>
							<option value={"sbpsber"}>СБП Сбер </option>
							<option value={"sbpraif"}>СБП Райфайзен </option>
							<option value={"sbpalfa"}>СБП Альфабанк </option>
							<option value={"sbpotkritie"}>СБП Открытие </option>
							<option value={"sbpvtb"}>СБП ВТБ </option>
							<option value={"sbpsovkombank"}>СБП Совкомбанк </option>
							<option value={"sbpgazprom"}>СБП Газпром </option>
							<option value={"sbprosbank"}>СБП Росбанк </option>
							<option value={"sber"}>СБЕР (Карта)</option>
						</select>
						<input
							value={requisites}
							onChange={(e) => {
								setRequisites(e.target.value);
							}}
							placeholder="Новые реквизиты"
						></input>
						<button
							onClick={() => {
								updateRequisites();
							}}
						>
							Сохранить
						</button>
					</>
				) : null}

				{props.orderInfo.status &&
				props.orderInfo.lolz &&
				(props.orderInfo.status === 4 ||
					props.orderInfo.status === "cancel") ? (
					<>
						<button
							className="Bottom"
							onClick={() => {
								checkManually();
							}}
						>
							Проверить вручную
						</button>
					</>
				) : null}
			</div>
		</>
	);
}

export default Overlay;
