import React, { useState, useRef } from 'react';
import { FaPaperclip } from 'react-icons/fa6';

const FileUploader = (props) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type.startsWith('video/'))) {
      uploadFile(file);
    } else {
      alert('Пожалуйста, выберите только изображение или видео файл.');
    }
  };

  const uploadFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = {
        fileName: file.name,
        fileType: file.type,
        fileData: event.target.result,
        to: props.activeDialog,
      };
      props.socket.emit('fileUpload', data);
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // скрываем input
      />
      <FaPaperclip onClick={handleClick}/>
    </div>
  );
};

export default FileUploader;
