import React, { useState, useEffect, useRef } from 'react';
import "../css/Notifications.css"

const Notification = ({ message, id, removeNotification }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeNotification(id);
    }, 5000);

    return () => clearTimeout(timer);
  }, [id, removeNotification]);

  return (
    <div className="Notification">
      {message}
    </div>
  );
};


const Notifications = ({ notifications, removeNotification }) => {
  return (
    <div className="NotificationsContainer">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          id={notification.id}
          message={notification.message}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  );
};


const NotificationSystem = React.forwardRef((props, ref) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message) => {
    const id = Date.now();
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, message },
    ]);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  // Используем useImperativeHandle для предоставления метода addNotification
  React.useImperativeHandle(ref, () => ({
    addNotification,
  }));

  return (
    <Notifications
      notifications={notifications}
      removeNotification={removeNotification}
    />
  );
});

export default NotificationSystem;