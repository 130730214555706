import useUser from "../hooks/useUser";
import { useEffect, useRef, useState } from "react";
import "../css/Settings.css";
import { FaPen, FaTrashCan } from "react-icons/fa6";
import UsersManagment from "../components/UsersManagment";

import NotificationSystem from "../components/Notifications";

function Admin() {
	const user = useUser();

	useEffect(() => {
		if (user.loaded && user.error) {
			window.location.href = "/auth";
		}

		if (user.loaded && user.status && user.status !== 3) {
			window.location.href = "/panel";
		}
	}, [user]);

	const [welcome, setWelcome] = useState("");
	const [token, setToken] = useState("secret");

	const [users, setUsers] = useState([]);

	const [logsDate, setLogsDate] = useState(
		new Date().toISOString().split("T")[0]
	);

	function convertSeconds(totalSeconds) {
		if(!totalSeconds) return "";
		totalSeconds = Math.floor(totalSeconds)
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;
	
		let result = '';
	
		if (hours > 0) {
			result += `${hours} час${hours === 1 ? '' : 'а'} `;
		}
	
		if (minutes > 0) {
			result += `${minutes} минут${minutes === 1 ? 'а' : 'ы'} `;
		}
	
		if (seconds > 0 || result === '') {  // Если ни часов, ни минут нет, всегда показываем секунды
			result += `${seconds} секунд${seconds === 1 ? 'а' : 'ы'}`;
		}
	
		return "AVG: " + result.trim();
	}

	const [modalUser, setModalUser] = useState({});
	const [modalOpened, setModalOpened] = useState(false);

	var logStrings = [
		'ID: ? отправил сообщение пользователю ?. | Текст: "?" | Медиа: "?" ',
		"ID: ? зашёл в панель",
		"ID: ? авторизовался на сайте",
		"ID: ? вышел из панели",
		"ID: ? сменил реквизиты на '?' в сделке №?"
	];

	function replacePlaceholders(str, values) {
		let index = 0;
		return str.replace(/\?/g, () => values[index++]);
	}

	function getFormattedDateTime(date) {
		date = new Date(date);

		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");

		return `${day}.${month} ${hours}:${minutes}`;
	}

	async function downloadLogs(logs) {
		var str = "";

		for (let i in logs) {
			str += `${getFormattedDateTime(logs[i].date)} | ${replacePlaceholders(
				logStrings[logs[i].logType],
				[logs[i].initiator, ...logs[i].info]
			)} \n`;
		}

		const blob = new Blob([str], { type: "text/plain" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", logsDate); // Укажите имя файла и расширение
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}

	function getWelcome() {
		fetch("/api/getWelcome", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return await res.json();
			})
			.then((res) => {
				setWelcome(res.welcome);
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при получении приветственного сообщения!");
			});
	}

	function updateWelcome() {
		fetch("/api/setWelcome", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({ welcome: welcome }),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				triggerNotification("Сообщение обновлено!");
				return await res.json();
			})
			.then((res) => {
				setWelcome(res.welcome);
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при обновлении приветственного сообщения!");
			});
	}

	function forceRestart() {
		fetch("/api/forceRestart", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				triggerNotification("Перезагрузите страницу!");
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при принудительной перезагрузке!");
			});
	}

	function updateToken() {
		fetch("/api/updateToken", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({ token: token }),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				triggerNotification("Токен обновлён");
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при обновлении токена!");
			});
	}

	function deleteMedia() {
		fetch("/api/deleteMedia", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				triggerNotification("Медиа удалены!");
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при отчистке медиа!");
			});
	}

	function getUsers() {
		fetch("/api/getUsers", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return await res.json();
			})
			.then((res) => setUsers(res))
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при получении пользователей!");
			});
	}

	function getLogs() {
		fetch("/api/getLogs", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({ logsDate: logsDate }),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return await res.json();
			})
			.then((res) => downloadLogs(res))
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при загрузке логов!");
			});
	}

	function deleteUser(id) {
		fetch("/api/deleteUser", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({ id: id }),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				getUsers();
				triggerNotification("Пользователь удалён!");
			})
			.catch((e) => {
				console.log("error");
				triggerNotification("Ошибка при удалении пользователя!");
			});
	}

	useEffect(() => {
		getWelcome();
		getUsers();
	}, []);

	useEffect(() => {
		getUsers();
	}, [modalOpened]);

	const notificationSystemRef = useRef();

	const triggerNotification = (t) => {
		if (notificationSystemRef.current) {
			notificationSystemRef.current.addNotification(t);
		}
	};

	return user.loaded && !user.error && user.status === 3 ? (
		<div className="Main">
			<div className="Window">
				<div className="Content">
					<div className="Settings">
						<div className="Block Header">
							<h1>Настройки</h1>
						</div>
						<div className="Block">
							<p>Приветственное сообщение</p>
							<input
								value={welcome}
								onChange={(e) => {
									setWelcome(e.target.value);
								}}
								placeholder="Текст"
							/>
							<button
								onClick={() => {
									updateWelcome();
								}}
							>
								Сохранить
							</button>
						</div>
						<div className="Block">
							<p>Токен бота</p>
							<input
								value={token}
								onChange={(e) => {
									setToken(e.target.value);
								}}
								placeholder="token"
							/>
							<button onClick={() => updateToken()}>Сохранить</button>
						</div>
						<div className="Block">
							<button
								onClick={() => {
									forceRestart();
								}}
							>
								Принудительный рестарт
							</button>
							<button
								onClick={() => {
									deleteMedia();
								}}
							>
								Отчистить все медиа
							</button>
						</div>
						<div className="Block UserList">
							<p>Пользователи</p>
							<div className="List">
								{users.map((x, key) => (
									<div key={key} className="User">
										<p>
											{x.login} ID: {x.id} {convertSeconds(x.avg)}
										</p>
										<FaTrashCan
											onClick={() => {
												deleteUser(x.id);
											}}
										/>
										<FaPen
											onClick={() => {
												setModalUser(x);
												setModalOpened(true);
											}}
										/>
									</div>
								))}
							</div>
							<button
								onClick={() => {
									setModalUser({});
									setModalOpened(true);
								}}
							>
								Добавить
							</button>
						</div>
						<div className="Block">
							<p>Логи</p>
							<div className="Logs">
								<input
									value={logsDate}
									onChange={(e) => {
										setLogsDate(e.target.value);
									}}
									type="date"
								/>
								<button onClick={() => getLogs()}>Скачать</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NotificationSystem ref={notificationSystemRef} />
			{modalOpened ? (
				<UsersManagment setModalOpened={setModalOpened} setModalUser={setModalUser} modalUser={modalUser} triggerNotification={triggerNotification}/>
			) : null}
		</div>
	) : null;
}

export default Admin;
